ul li.arrow {
  position: relative;
  padding-bottom: 10px;
  line-height: 1rem;
  margin-top: 0.2rem;
  list-style: none;
}

ul li.arrow:before {
  content: '';
  position: absolute;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  width: 10px;
  height: 10px;
  top: 10px;
  left: -20px;
  transform: translateY(-50%) rotate(-45deg);
}
