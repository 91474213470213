@import '../../components/globalVariables.scss';

.questions--title {
  font-size: 2em;
  line-height: 1em;
  font-weight: 500;
  margin: 0;
  padding: 0 0 20px 0;
  @include desktop {
    padding: 0;
  }
}

.questions--header--navi {
  display: block;
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
}

.questions--navi {
  display: flex;
}

.questions--footer--navi {
  display: flex;
  justify-content: space-between;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: $primary-color;
    font-weight: bold;
  }
}
