@import '../../components/globalVariables.scss';

.instructionImg {
  width: 100%;
  @include desktop {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
