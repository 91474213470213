.formplus--holder {
  float: right;
  margin-right: 35px;
  .plus {
    margin-top: 60px;
    position: absolute;
    font-size: 2em;
    cursor: pointer;
  }
}

.forminfo--holder {
  float: right;
  margin-right: 280px;

  .text {
    position: absolute;
    margin-top: 60px;
    font-size: 0.7em;
    width: 278px;
    background-color: #fff;
    border: 1px solid #fdb813;
    padding: 30px 10px 10px 10px;
  }
}
