@import '../../components/globalVariables.scss';

.categories {
  display: grid;
  grid-column-gap: 9px;
  cursor: pointer;

  -ms-grid-columns: (1fr) [1];
  grid-template-columns: repeat(1, 1fr);

  &.c9 {
    -ms-grid-rows: (209px) [9];
    grid-template-rows: repeat(9, 209px);
    @include desktop {
      -ms-grid-rows: (209px) [3];
      grid-template-rows: repeat(3, 209px);
      -ms-grid-columns: (1fr) [3];
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.c7 {
    -ms-grid-rows: (209px) [7];
    grid-template-rows: repeat(7, 209px);
    @include desktop {
      -ms-grid-rows: (209px) [3];
      grid-template-rows: repeat(3, 209px);
      -ms-grid-columns: (1fr) [3];
      grid-template-columns: repeat(3, 1fr);
    }
  }

  a {
    color: $primary-bg-color;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1em;
    line-height: 1em;
    font-weight: bold;
  }

  img {
    height: 40px;
  }
}

.main--guide {
  font-size: 1.1em;
  font-weight: bold;
}
