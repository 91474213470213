@import '../globalVariables.scss';

body {
  background-color: $primary-bg-color;
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: 500;
  font-style: normal;
  color: $primary-color;
  text-align: center;
}

a {
  color: $link-color;
}

h1 {
  font-family: $body-font;
  font-style: normal;
  color: $primary-color;
}
