@import '../../components/globalVariables.scss';
.footer--navi {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  @include desktop {
    flex-direction: row;
  }
  .back--to--main--link {
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    color: $primary-color;
    font-weight: bold;
  }

  .footer--navi--container {
    line-height: '2em';
    padding-top: 13px;

    .back--button {
      font-size: 0.7em;
      margin-bottom: 4px;
      padding-right: 7px;
      float: left;
    }
  }

  .footer--navi--top {
    padding-top: 0;
    padding-bottom: 20px;
  }

  &.preinfo {
    flex-direction: row;
    .prevnext--navi {
      width: 50%;
    }
  }
  .prevnext--navi {
    justify-content: flex-end;
    width: 100%;
    display: flex;
    @include desktop {
      width: 50%;
    }
    .submit {
      width: 50%;
    }
    .submit:nth-child(1) {
      margin-right: 5px;
    }
  }
}
