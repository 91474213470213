@import '../../components/globalVariables.scss';
.form h1 {
  line-height: 1em;
  text-align: center;
  @include desktop {
    //font-size: 1em;
    line-height: 1.1em;
  }
}

.logo--holder {
  @include desktop {
    width: 20%;
  }
}

h2 {
  padding-top: 1em;
  font-size: 1.2em;
  font-weight: 600;
  font-style: normal;
}

// Hide Preinfo link
.header.form .page-nav ul li a.preinfo {
  display: none;
}

.autoresize-field {
  width: 97%;
  border-radius: 4px;
  border-color: #eee;
  padding: 10px;
  font-family: benton-sans, sans-serif;
  font-size: 15px;
}

@media print {
  .header--navi,
  .MuiButton-root,
  .footer--navi--container,
  .no-print,
  .no-print * {
    display: none !important;
  }
  .white--wrapper,
  .blue--wrapper,
  .MuiContainer-root {
    margin: 0 !important;
    padding: 0 !important;
  }
  .MuiTextField-root {
    display: none !important;
  }
  h1 {
    text-align: right;
  }
  h2 {
    padding-top: 0;
  }
  .autoresize-field {
    display: block;
  }
}
