@import '../globalVariables.scss';

.header {
  padding: 2px 20px 2px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 46px;
  align-items: center;
  @include desktop {
    height: 80px;
  }
}

.header.start {
  height: 80px;
  margin-bottom: 30px;
  @include desktop {
    height: 140px;
    margin-bottom: 0;
  }
}

.site--title {
  width: 60%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;

  h1 {
    margin-left: 0;
    @include mobileDesktopBetween {
      margin-right: 120px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      font-size: 1.8em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1150px) {
      font-size: 1.7em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1100px) {
      font-size: 1.6em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1050px) {
      font-size: 1.4em;
    }

    @media only screen and (min-width: 768px) and (max-width: 1000px) {
      font-size: 1.3em;
    }

    @media only screen and (min-width: 768px) and (max-width: 950px) {
      font-size: 1.2em;
    }

    @media only screen and (min-width: 768px) and (max-width: 900px) {
      font-size: 1.1em;
    }

    @media only screen and (min-width: 768px) and (max-width: 850px) {
      font-size: 1em;
      font-weight: bold;
    }
  }
}

.logo--holder {
  //width: 30%;
  height: 40px;
  @include desktop {
    height: 80px;
  }
}

h1 {
  align-self: center;
  display: inline;
  font-size: 1em;
  @include desktop {
    font-size: 2em;
    font-weight: 500;
  }
}

a.info {
  margin-top: 1rem;
  @include desktop {
    margin-left: 2rem;
  }
}

.header--div--link {
  color: #006f9d;
  border-bottom: 1px solid #58cbf4;
  padding-bottom: 1px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  @include desktop {
    margin-left: 0;
    margin-right: 0;
  }
}

.header img {
  position: absolute;
  height: 40px;
  width: 43px;
  @include desktop {
    height: 80px;
    width: 86px;
  }
}

.header.start img {
  height: 90px;
  width: 97px;
  @include desktop {
    height: 140px;
    width: 151px;
  }
}

.header--navi {
  color: $primary-color;
  font-family: $body-font;
  font-size: 0.7333rem;
  font-weight: $body-font-weight;
  text-transform: uppercase;
  //width: 30%;
  display: flex;
  justify-content: flex-end;
  @include desktop {
    //width: initial;
  }
}

.header--navi a {
  margin: 20px;
  color: $primary-color;
  border-bottom: 1px solid $link-color;
  padding-bottom: 1px;
  text-decoration: none;
  @include desktop {
    margin: 0;
  }
}

h1 {
  padding: 0;
  margin: 0;
}

.header.start h1 {
  display: none;
}

.page-nav {
  //position: absolute;
  background: #fff;
  text-align: right;
  background: #fff;
}

.page-nav label,
#hamburger {
  display: none;
}

/* Show Hamburger */
.page-nav label {
  display: inline-block;
  color: #58cbf4;
  background: #fff;
  font-style: normal;
  font-size: 1.5rem;
  cursor: pointer;
  @include desktop {
    display: none;
    padding-right: 10px;
  }
}

/* Break down menu items into vertical */
.page-nav ul li {
  padding-bottom: 10px;
  @include desktop {
    //padding: 10px;
    box-sizing: border-box;
  }
}

/* Toggle show/hide menu on checkbox click */
.page-nav ul {
  background: #fff;
  position: absolute;
  right: 0;
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include desktop {
    right: 10px;
    display: inline;
    position: relative;
  }
}
.page-nav input:checked ~ ul {
  display: block;
}
