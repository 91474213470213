@import '../../components/globalVariables.scss';

.cardimage {
  width: 100%;
  @include desktop {
    float: right;
    width: 400px;
    height: 282px;
  }
}
