@import '../../components/globalVariables.scss';

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.logo--hotus {
  width: 108px;
  height: 45px;
  padding-top: 3px;
  padding-right: 10px;
  @include desktop {
    width: 157px;
    height: 66px;
  }
}

.logo--muistiliitto {
  width: 108px;
  height: 44px;
  padding-right: 30px;
  @include desktop {
    width: 184px;
    height: 75px;
  }
}

.logo--veikkaus {
  width: 33px;
  height: 33px;
  padding-top: 8px;
  padding-right: 20px;
  @include desktop {
    width: 55px;
    height: 55px;
  }
}
