@import '../../components/globalVariables.scss';

.start {
  .page-nav label {
    display: none;
  }
  .page-nav ul {
    padding: 8px;
    display: block;
  }

  .header {
    align-items: normal;
  }
}

.init {
  display: block;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  @include desktop {
    display: flex;
  }
}

.init--text {
  width: 100%;
  line-height: 1.467rem;
  @include desktop {
    width: 39%;
  }
}

.init--image {
  width: 100%;
  @include desktop {
    width: 56%;
  }
}

.init--image img {
  width: 100%;
}

h1 {
  color: $primary-color;
}

.box--container {
  padding: 30px 0;
  @include desktop {
    height: 300px;
    padding: 67px 0;
    display: flex;
    flex-direction: row;
  }
}

.box {
  height: 100%;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  font-weight: 500;
  //cursor: pointer;
  flex-direction: column;
  height: 300px;
  text-align: center;
  line-height: 1em;

  a {
    text-decoration: none;
    color: #fff;
  }
  @include desktop {
    width: 33%;
  }

  @include mobileDesktopBetween {
    height: 250px;
    font-size: 1.3em;
  }

  img {
    height: 81px;
    display: block;
    padding-bottom: 20px;

    margin-left: auto;
    margin-right: auto;
  }

  a {
    max-width: 80%;
  }
}

.boxOne {
  background-color: $primary-box-bg-color;
  margin-bottom: 10px;
  @include desktop {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.boxTwo {
  background-color: $secondary-box-bg-color;
  margin-bottom: 10px;
  @include desktop {
    margin-right: 10px;
    margin-bottom: 0;
    img {
      padding-bottom: 40px;
    }
  }
  span {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.boxThree {
  background-color: $primary-color;
}

.boxOne span,
.boxThree span {
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.page-nav ul.start li {
  @include desktop {
    display: inline;
    padding: 10px;
  }
}
