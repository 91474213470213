@import '../../components/globalVariables.scss';

.questionNaviItem {
  background-color: $inactive-color;
  cursor: pointer;
  height: 27px;
  width: 27px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary-color;

  &.currentItem,
  &.filledItem {
    color: #fff;
    font-weight: bold;
  }
}

.filledItem {
  background-color: $primary-box-bg-color;
}

.currentItem {
  background-color: $link-color;
}
