@import './globalVariables.scss';

.blue--wrapper {
  background-color: $secondary-bg-color;

  @media print {
    background-color: none;
  }

  .MuiButton-root {
    border: 0;
    border-radius: 5px;
    color: $primary-bg-color;
  }

  .MuiButton-outlined {
    &.submit,
    &.full {
      background-color: $primary-color;
      text-transform: uppercase;
      font-size: 1em;
      font-weight: bold;
    }
  }
}

.white--wrapper {
  background-color: $primary-bg-color;
}

.lightblue--wrapper {
  background-color: $lightblue;
  border: 1px solid $lightblue-border;
}

.lightyellow--wrapper {
  background-color: $lightyellow;
  border: 1px solid $lightyellow-border;
}

.lightred--wrapper {
  background-color: $lightred;
  border: 1px solid $lightred-border;
}

.section--wrapper {
  border-radius: 5px;
  margin-bottom: 20px;

  padding: 20px;

  &.header--wrapper {
    padding: 0;
    margin: 0;
  }

  .MuiContainer-root {
    padding: 0;
    @include desktop {
      padding: 20px;
    }
  }
  .MuiButton-root {
    border: 0;
    border-radius: 5px;
  }

  label.Mui-focused {
    color: $primary-color;
  }
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: $primary-color;
    }
    &.focused fieldset {
      border-color: $primary-color;
    }
  }
  .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: $primary-color;
  }

  .MuiButton-outlined {
    font-weight: bold;

    &.active {
      background-color: $primary-box-bg-color;
    }

    &.inactive {
      background-color: $inactive-color;
      color: $primary-color;
    }

    &.submit,
    &.full {
      background-color: $primary-color;
    }

    &.Mui-disabled {
      border: 0;
      background-color: $inactive-color;
    }
  }

  .MuiButton-root {
    color: $primary-bg-color;
  }
}

.smallMarginBottom {
  margin-bottom: 10px;
}

.container {
  max-width: 1500px;
  margin: auto;
  text-align: left;
}
