.notice--section {
  h2 {
    margin-top: 0;
    padding-top: 0;
    font-size: 1.2em;
  }

  ul li {
    padding-bottom: 1em;
  }
}

.report--question--category {
  font-weight: bold;
  font-size: 0.7em;
  padding-bottom: 1em;
}

.report--question {
  font-size: 1;
  padding-bottom: 1em;
}

.report--critical {
  font-weight: bold;
  text-transform: uppercase;
  float: right;
}

.report--answer {
  font-size: 0.8em;
}

.report--header {
  h2 {
    font-size: 2em;
    font-weight: 500;
    padding: 0;
    margin: 0;
  }
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    font-weight: bold;
    text-transform: uppercase;

    div {
      margin-top: 4px;
      cursor: pointer;
      padding-right: 10px;
    }
  }

  .print--image {
    cursor: pointer;

    height: 23px;
    width: 21px;
  }
}

@media print {
  .report--header h2 {
    padding: 0 1em 1em 0;
  }

  .notice--section,
  .needsattention--section {
    padding-bottom: 2em;
  }
  /*  .header--navi,
  .MuiButton-root,
  .footer--navi--container,
  .no-print,
  .no-print * {
    display: none !important;
  }
  .white--wrapper,
  .blue--wrapper,
  .MuiContainer-root {
    margin: 0 !important;
    padding: 0 !important;
  }
  h1 {
    text-align: right;
  }
  h2 {
    padding-top: 0;
  }
  */
}
