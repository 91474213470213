.question--holder {
  display: flex;
  align-items: center;

  input {
    margin-right: 30px;
  }

  label {
    cursor: pointer;
  }
}

.question--category {
  font-size: 0.8em;
  font-weight: 500;
}

.question {
  padding-top: 1em;
  font-size: 1.2em;
}
