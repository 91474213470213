@import '../../components/globalVariables.scss';

.white--wrapper .MuiButtonBase-root {
  margin: 10px 0;
}

.MuiButton-outlined {
  width: 100%;
  &.submit {
    width: 50%;
    @include desktop {
      width: 25%;
    }
  }
}

.buttonHolder {
  @include desktop {
    display: flex;
  }
}

.buttonMargin {
  display: none;
  @include desktop {
    display: block;
    width: 20px;
  }
}

.submit--holder {
  width: 100%;
  text-align: right;
}
