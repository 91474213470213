$primary-bg-color: #ffffff;
$secondary-bg-color: #e1f5fd;
$secondary-box-bg-color: #10709b;
$primary-box-bg-color: #10709b;
$lightblue: #c9eff6;
$lightblue-border: #01aac5;
$lightyellow: hsl(43, 100%, 91%);
$lightyellow-border: #fdb813;
$lightred: #feeae8;
$lightred-border: #f79389;
$primary-color: #10709b;
$link-color: #035972;
$inactive-color: #daebf3;
$body-font-size: 15px;
$body-font: benton-sans, sans-serif;
$body-font-weight: 500;
$body-font-style: normal;

@mixin desktop {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin mobileDesktopBetween {
  @media only screen and (min-width: 768px) and (max-width: 960px) {
    @content;
  }
}
